@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}



.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #7de7eb 25.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}



.bg-button-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 118.29%
  );
}

.bg-button-grey{
    background-color: rgb(55, 65, 81);
}

.bg-button-login-gradient {
  background: linear-gradient(
    157.81deg,
    #a4fca3 -43.27%,
    #7ef47f -21.24%,
    #5cea5c 12.19%,
    #3fe639 29.82%,
    #1ad312 51.94%,
    #0cb408 118.29%
  );
}

.login-color{
  background: linear-gradient(
    157.81deg,
    #3fe639 29.82%,
    #1ad312 61.94%,
    #0cb408 108.29%
  );

}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #749BC2 -100.47%,
    #91C8E4 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}



.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.navbar-title {
  font-size: 1.3em;
  font-family: 'Poppins', sans-serif;
}

.carousel-container {
  max-width: 298px;
}

.thumbnail-item{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 295px;
  height: 200px;
}

/* Penilaian */
.penilaian span {
  display: inline-flex;
  flex-flow: row-reverse;
}



.penilaian label{
  font-size: 2rem;
  cursor: pointer;
  color: lightgrey;
  margin: 0 5px;
  text-shadow: 1px 1px #bbb;
 
}

.rtl {
  direction: rtl;
}
.penilaian:not(:checked) > span input {
  position: absolute;
  right: 100vw;
}

.penilaian:not(:checked) > span label {
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 18px;
  color: #ccc;
}


.penilaian:not(:checked) > span label:before {
  content: "★ ";
}

.penilaian > span input:checked ~ label {
  color: gold;
}

.penilaian:not(:checked) > span label:hover,
.penilaian:not(:checked) > span label:hover ~ label {
  color: gold;
}

/* product rate */

.rate {
  display: flex;
  align-items: center;
}

.rate p {
  margin-right: 10px;
  margin-bottom: 1rem;
}

.rate label {
  float: right;
  font-size: 1.8rem;
  color: lightgrey;
  margin-bottom: .85rem;
  margin-right: .2rem;
  text-shadow: 1px 1px #bbb;
}

.rate label:before {
  content: '★' ;
  color: gold ;
  box-shadow: goldenrod;
}

.rate :not(:checked) > label:hover,
.rate :not(:checked) > label:hover ~ label {
  color: black;
}

.rate .result:before {
  position: absolute;
  content: "";
  width: 100%;
  left: 50%;
  transform: translateX(-47%);
  bottom: -30px;
  font-size: 30px;
  font-weight: 500;
  color: gold;
  font-family: 'Poppins', sans-serif;
  display: none;
}

.rate input{
  display: none;
}

.star.active {
  --active-color: #ffd700; /* Warna emas */
}

.star.inactive {
  --inactive-color: #c9d0d6; /* Warna abu-abu */
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  border-radius: 1rem;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
